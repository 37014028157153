import {useState} from "react";
import {useDebouncedState} from "@mantine/hooks";
import DefaultTable from "../../components/DefaultTable";
import {IDeviceCreate, IDevice} from "../../api/types";
import {useCreateDeviceMutation, useGetDevicesQuery, useUpdateDeviceMutation} from "../../api/device";
import PageContainer from "../../components/PageContainer";
import {Button, Card, Flex} from "@mantine/core";
import UploadDevices from "./UploadDevices";
import {IconPlus, IconUpload} from "@tabler/icons-react";
import CreateDevice from "./CreateDevice";
import {createFormContext} from "@mantine/form";
import UserForm from "../users/UserForm";
import CrudModal from "../../components/CrudModal";
import {UserFormProvider} from "../users/Users";
import {IUser} from "../../api/user";
import {notifications, showNotification} from "@mantine/notifications";
import {isErrorWithMessage} from "../../api/api";

export const [DeviceFormProvider, useFormContext, useDeviceForm] = createFormContext<IDeviceCreate & {_id: string}>();
const initialModalState = {
    opened: false
}
const Devices = () => {
    const [modalState, setModalState] = useState<{ opened: boolean, record?: IUser }>(initialModalState)
    const [page, setPage] = useState(1)
    const [isOpenUpload, setIsOpenUpload] = useState<boolean>(false)
    const [search, setSearch] = useDebouncedState('', 200);
    const {data = {content: [], totalPages: 0}, isFetching} = useGetDevicesQuery({page, pageSize: 10, search})
    const [updateDevice] = useUpdateDeviceMutation();
    const [createDevice] = useCreateDeviceMutation();
    function onRowClick(record: IDevice) {

    }

    function onCloseUpload() {
        setIsOpenUpload(false)
    }

    async function onDelete() {
        if (modalState && modalState.record && modalState.record._id) {
            //await deleteUser({_id: modalState.record._id})
            onCloseCreate();
            showNotification({color: 'green', message: 'Пользователь удален'})
        }
    }
    async function onSubmit(values: IDeviceCreate & {_id: string}) {
        try {
            if (values._id) {
                await updateDevice(values).unwrap()
                showNotification({color: 'green', message: 'Устройство обновлено'})
                onCloseCreate();
                return;
            }
            await createDevice(values).unwrap()
            showNotification({color: 'green', message: 'Устройство создано'})
            onCloseCreate();
        } catch (e) {
            if (isErrorWithMessage(e)) {
                notifications.show({color: 'red', message: e.data.message})
                return
            }
            notifications.show({color: 'red', message: 'Произошла ошибка'})
        }

    }
    function onCloseCreate() {
        setModalState((initialModalState))
        form.reset();
    }
    const form = useDeviceForm({
        initialValues: {
            _id: '',
            type: '',
            brand: '',
            model: '',
            specs: '',
            serial: '',
            deviceNumber: ''
        }
    })
    return (
        <PageContainer>
            <Flex justify={'flex-end'} mb={10}>
                <Button onClick={() => setIsOpenUpload(true)}
                        leftIcon={<IconUpload/>}
                >
                    Загрузить устройства
                </Button>

                <Button onClick={() => setModalState({opened: true})}
                        leftIcon={<IconPlus/>}
                        ml={5}
                        variant={'outline'}
                >
                    Создать устройство
                </Button>
                <UploadDevices isOpen={isOpenUpload} onClose={onCloseUpload}/>
                <CrudModal opened={modalState.opened}
                           onClose={onCloseCreate} onDelete={onDelete}
                           isUpdate={Boolean(modalState.record)}
                           formId={'deviceForm'}
                >
                    <DeviceFormProvider form={form}>
                        <CreateDevice recordId={modalState.record?._id} onSubmit={onSubmit}/>
                    </DeviceFormProvider>
                </CrudModal>

            </Flex>
            <Card shadow={'lg'} mt={25}>
            <DefaultTable
                data={data}
                columns={[
                    {name: 'deviceNumber', label: 'Артикул'},
                    {name: 'type.name', label: 'Тип'},
                    {name: 'brand.name', label: 'Бренд'},
                    {name: 'model.name', label: 'Модель'},
                    {name: 'serial', label: 'Серийный номер'},
                ]}
                onChangePage={setPage}
                isFetching={isFetching}
                page={page}
                onSearch={setSearch}
                enableSearch={true}
                searchPlaceholder={'Поиск по всем полям'}
                onRowClick={onRowClick}
            />
            </Card>
        </PageContainer>
    )
}

export default Devices;