import {useGetOrderNotesQuery} from "../../api/order";
import {useParams} from "react-router-dom";
import {Avatar, Box, Group, Paper, Text, TypographyStylesProvider} from '@mantine/core';
import classes from './orderNotes.module.css';
import dayjs from "dayjs";

const OrderNotes = () => {
    const {id} = useParams();
    const {data, isFetching} = useGetOrderNotesQuery({id: id || ""}, {skip: !id});
    return (
        <Box mt={5}>
            {data?.content?.map(value => {
                return (
                    <Paper withBorder radius="md" className={classes.comment}>
                        <Group>
                            <Avatar
                                src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png"
                                alt="Jacob Warnhalter"
                                radius="xl"
                            />
                            <div>
                                <Text fz="sm">{value.manager.name}</Text>
                                <Text fz="xs" c="dimmed">
                                    {dayjs(value.date).format('DD-MM-YYYY HH:mm')}
                                </Text>
                            </div>
                        </Group>
                        <TypographyStylesProvider className={classes.body}>
                            {value.note}
                        </TypographyStylesProvider>
                    </Paper>
                )
            })}
        </Box>
    )
}

export default OrderNotes;