import React from "react";
import {Button, Divider, Modal, Paper} from "@mantine/core";
import {useConfirmationModal} from "../hooks/useConfirmationModal";

interface IProps {
    opened: boolean,
    isUpdate?: boolean,
    children: React.ReactNode,
    formId: string,
    title?: string,

    onClose(): void,

    onDelete(): void,
}

const CrudModal = (props: IProps) => {
    const {opened, onClose, onDelete, children, isUpdate, formId, title} = props;
    const [ConfirmModal, confirmDelete] = useConfirmationModal({})

    async function onClickConfirm() {
        const res = await confirmDelete();
        if (res) await onDelete();
    }

    return (
        <Modal opened={opened} onClose={onClose} centered size={"xl"} title={title}>
            <Divider mb={20}/>
            {children}
            <Divider mt={20} mb={10}/>
            <Paper sx={{display: 'flex', justifyContent: 'flex-end'}}>
                {isUpdate && (
                    <Button onClick={onClickConfirm} variant={"outline"}>
                        Удалить
                    </Button>
                )}
                <Button ml={5} type={'submit'} form={formId}>
                    {isUpdate ? 'Обновить' : 'Сохранить'}
                </Button>
            </Paper>
            <ConfirmModal/>
        </Modal>
    )
}

export default CrudModal;
