import {
    Autocomplete, Box,
    Button,
    createStyles,
    Flex,
    Modal,
    Select,
    Textarea,
    TextInput,
    useMantineTheme
} from "@mantine/core";
import {useCreateDeviceTypeMutation, useGetDeviceTypesQuery} from "../../api/deviceType";
import {useCreateDeviceBrandMutation, useGetDeviceBrandsQuery} from "../../api/deviceBrand";
import {useCreateDeviceModelMutation, useGetDeviceModelsQuery} from "../../api/deviceModel";
import {useState} from "react";
import {useFormContext} from "./Devices";
import {IDeviceCreate} from "../../api/types";

type TProps = {
    onSubmit: (user: IDeviceCreate & { _id: string }) => void,
    recordId?: string
}
const useClientStyles = createStyles((theme) => ({
    dropdownItem: {
        padding: 5,
        cursor: "pointer",
        "&:hover": {
            background: theme.colors.gray[2]
        }
    },
    rowContainer: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        padding: '10px 0'
    },
    divider: {
        padding: "5px 0 10px 0"
    }
}))
const CreateDevice = ({recordId, onSubmit}: TProps) => {
    const theme = useMantineTheme();
    const {classes} = useClientStyles();
    const form = useFormContext();
    const [openedDropdown, setOpenedDropdown] = useState<'type' | 'model' | 'brand' | null>(null)
    const {data: deviceTypes = {content: []}, isFetching: isFetchingDeviceTypes} = useGetDeviceTypesQuery({
        page: 1, pageSize: 999, sort: {column: "name", direction: 'ASC'}
    });

    const {data: deviceBrands = {content: []}, isFetching: isFetchingDeviceBrands} = useGetDeviceBrandsQuery({
        page: 1, pageSize: 999,
        filter: JSON.stringify({
            type: form.getInputProps('type').value
        }),
        sort: {column: "name", direction: 'descend'}
    }, {skip: !Boolean(form.getInputProps('type').value)});

    const {data: deviceModels = {content: []}, isFetching: isFetchingDeviceModels} = useGetDeviceModelsQuery({
        page: 1, pageSize: 999,
        filter: JSON.stringify({
            type: form.getInputProps('type').value,
            brand: form.getInputProps('brand').value
        })
    }, {skip: !Boolean(form.getInputProps('type').value && form.getInputProps('brand').value)});

    const [createDeviceType, createdDeviceType] = useCreateDeviceTypeMutation();
    const [createDeviceBrand, createdDeviceBrand] = useCreateDeviceBrandMutation();
    const [createDeviceModel, createdDeviceModel] = useCreateDeviceModelMutation();

    return (
        <form id={'deviceForm'} onSubmit={form.onSubmit(async (values) => {
           await onSubmit(values)
        })}>
            <Box py={20}>
                <div className={classes.rowContainer}>
                    <TextInput
                        withAsterisk={false}
                        required={true}
                        w={350}
                        mb={10}
                        label={"Номер устройства (Артикул)"}
                        {...form.getInputProps('deviceNumber')}
                    />
                    <TextInput
                        withAsterisk={false}
                        required={true}
                        w={350}
                        mb={10}
                        label={"Серийный номер"}
                        {...form.getInputProps('serial')}
                    />
                </div>
                <div className={classes.rowContainer}>
                    <Select
                        label="Тип"
                        required={true}
                        disabled={Boolean(!Boolean(form.getInputProps('serial').value) || !Boolean(form.getInputProps('deviceNumber').value))}
                        searchable
                        creatable
                        placeholder="Выбирите тип"
                        data={deviceTypes.content.map(item => {
                            return {label: item.name, value: item._id || ""}
                        })}
                        onDropdownOpen={() => setOpenedDropdown('type')}
                        onDropdownClose={() => setOpenedDropdown(null)}
                        getCreateLabel={(query) => `+ Создать ${query}`}
                        onCreate={query => {
                            createDeviceType({
                                name: query
                            })
                            return {value: "", name: query}
                        }}
                        {...form.getInputProps('type')}
                    />
                    <Select
                        label="Бренд"
                        required={true}
                        disabled={!Boolean(form.getInputProps('type').value)}
                        searchable
                        creatable
                        placeholder="Выбирите бренд"
                        data={deviceBrands.content.map(item => {
                            return {label: item.name, value: item._id || ""}
                        })}
                        onDropdownOpen={() => setOpenedDropdown('brand')}
                        onDropdownClose={() => setOpenedDropdown(null)}
                        getCreateLabel={(query) => `+ Создать ${query}`}
                        onCreate={query => {
                            createDeviceBrand({
                                type: form.getInputProps('type').value,
                                name: query
                            })
                            return {value: "", name: query}
                        }}
                        {...form.getInputProps('brand')}
                    />
                    <Select
                        label="Модель"
                        required={true}
                        disabled={!Boolean(form.getInputProps('type').value && form.getInputProps('brand').value)}
                        searchable
                        creatable
                        placeholder="Выбирите модель"
                        data={deviceModels.content.map(item => {
                            return {label: item.name, value: item._id || ""}
                        })}
                        onDropdownOpen={() => setOpenedDropdown('model')}
                        onDropdownClose={() => setOpenedDropdown(null)}
                        getCreateLabel={(query) => `+ Создать ${query}`}
                        onCreate={query => {
                            createDeviceModel({
                                type: form.getInputProps('type').value,
                                brand: form.getInputProps('brand').value,
                                name: query
                            })
                            return {value: "", name: query}
                        }}
                        {...form.getInputProps('model')}
                    />
                </div>
                <div className={classes.rowContainer}>
                    <Textarea
                        style={{width: '100%'}}
                        label="Тех. Характеристики"
                        {...form.getInputProps('specs')}
                        disabled={!Boolean(form.getInputProps('type').value && form.getInputProps('brand').value)}
                    />
                </div>
            </Box>
        </form>
    )
}

export default CreateDevice