import {Routes, Route} from "react-router-dom";
import Login from "./pages/authorization/Login";
import Home from "./pages/home/Home";
import Services from "./pages/cms/services/Services";
import ServicePositions from "./pages/cms/servicePositions/ServicePositions";
import CreateServicePosition from "./pages/cms/servicePositions/CreateServicePosition";
import OrderRead from "./components/orderRead/OrderRead";
import Finances from "./pages/finances/Finances";
import Analytics from "./pages/analytics/Analytics";
import Feedback from "./pages/cms/feedback";
import OldOrders from "./pages/oldOrders/OldOrders";
import Users from "./pages/users/Users";
import Offices from "./pages/offices/Offices";
import Executors from "./pages/executors/Executors";
import Clients from "./pages/clients/Clients";
import Devices from "./pages/devices/Devices";
import WhereKnown from "./pages/whereKnown/WhereKnown";
import DeviceAppearance from "./pages/deviceAppearance/DeviceAppearance";
import Orders from "./pages/orders/Orders";
import RefusedOrders from "./pages/refusedOrders/RefusedOrders";
import Prices from "./pages/cms/prices/prices";

export const useRoutes = (isAuthenticated: boolean) => {
    if (Boolean(isAuthenticated)) {
        return (
            <Routes>
                <Route path={"/"} element={<Home/>}/>
                <Route path={"/order/:id?"} element={<OrderRead/>}/>
                <Route path={"/finances"} element={<Finances/>}/>
                <Route path={"/analytics"} element={<Analytics/>}/>
                <Route path={"/orders-migrated"} element={<OldOrders/>}/>
                <Route path={"/orders"} element={<Orders/>}/>
                <Route path={"/refused-orders"} element={<RefusedOrders/>}/>
                <Route path={"/users"} element={<Users/>}/>
                <Route path={"/offices"} element={<Offices/>}/>
                <Route path={"/executors"} element={<Executors/>}/>
                <Route path={"/clients"} element={<Clients/>}/>
                <Route path={"/devices"} element={<Devices/>}/>
                <Route path={"/where-known"} element={<WhereKnown/>}/>
                <Route path={"/device-appearance"} element={<DeviceAppearance/>}/>
                <Route path={"/cms/feedback"} element={<Feedback/>}/>
                <Route path={"/cms/services"} element={<Services/>}/>
                <Route path={"/cms/prices"} element={<Prices/>}/>
                <Route path={"/cms/services/:serviceTag/servicePositions"} element={<ServicePositions/>}/>
                <Route path={"/cms/services/:serviceTag/servicePositions/servicePosition/:id?"}
                       element={<CreateServicePosition/>}/>
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path={"/"} element={<Login/>}/>
        </Routes>
    )
}