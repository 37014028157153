import React, {useEffect} from "react";
import {
    ActionIcon,
    Burger,
    Button,
    createStyles,
    Header,
    Indicator,
    MediaQuery,
    Tooltip,
    useMantineTheme
} from "@mantine/core";
import {
    IconSun,
    IconBell,
    IconUserCircle,
    IconMoonStars,
    IconSquareRoundedPlus
} from "@tabler/icons-react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {toggleColorScheme, openCreateOrder, toggleSideBar} from "../../app/app.slice";
import OfficeSelector from "../officeSelector/OfficeSelector";
import OrderForm from "../orderForm/CreateOrder";
import {Link} from "react-router-dom";
import {useCountUnreadQuery} from "../../api/feedback";
import {api} from "../../api/api";
import {useMediaQuery} from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
    actionIcon: {
        marginLeft: 10
    },
    icon: {
        color: theme.colors.gray[6]
    },
    appContainer: {
        display: 'flex',
        padding: "10px 5px",
        alignItems: 'center',
        height: '100%',
    }
}))
const AppHeader = () => {
    const isOpenSidebar = useAppSelector(state => state.app.isOpenSidebar)
    const role = useAppSelector(state => state.authorization.user.role)
    const isSale = role === "admin" || role === "manager";
    const theme = useMantineTheme();
    const dispatch = useAppDispatch();
    const isSmallScreen = useMediaQuery('(max-width: 500px)');
    const {classes} = useStyles();
    return (
        <Header height={{base: 60, md: 70}} p="md" className={"width-before-scroll-bar"}>
            <div className={classes.appContainer}>
                <MediaQuery largerThan="lg" styles={{display: 'none'}}>
                    <Burger
                        opened={isOpenSidebar}
                        onClick={() => dispatch(toggleSideBar())}
                        size="sm"
                        color={theme.colors.gray[6]}
                        mr="xl"
                    />
                </MediaQuery>
                <div style={{flexGrow: 1}}>
                    {isSale && (
                        !isSmallScreen && (
                            <OfficeSelector/>
                        )
                    )}
                </div>
                <ThemeChanger/>
                {isSale && (
                    <>
                        {/*<Notifications/>*/}
                        <NewOrderButton/>
                        <OrderForm/>
                    </>
                )}
            </div>
        </Header>
    )
}
export default AppHeader;

const NewOrderButton = () => {
    const {classes} = useStyles();
    const dispatch = useAppDispatch()
    const isSmallScreen = useMediaQuery('(max-width: 500px)');
    if (isSmallScreen) {
        return (
            <>
                <Button variant="filled" className={classes.actionIcon}
                        onClick={() => dispatch(openCreateOrder({}))}
                        color={'cyan'}
                >
                    Создать
                </Button>
            </>
        )
    }
    return (
        <Button variant="filled" className={classes.actionIcon}
                onClick={() => dispatch(openCreateOrder({}))}
                color={'cyan'}
        >
            Создать Заказ
        </Button>
    )
}
const ThemeChanger = () => {
    const {colorScheme} = useAppSelector(state => state.app)
    const dispatch = useAppDispatch();
    return (
        <ActionIcon
            onClick={() => dispatch(toggleColorScheme())}
            size="lg"
            sx={(theme) => ({
                backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                color: theme.colorScheme === 'dark' ? theme.colors.yellow[4] : theme.colors.blue[6],
            })}
            variant={'default'}
        >
            {colorScheme === 'dark' ? <IconSun size="1.2rem"/> : <IconMoonStars size="1.2rem"/>}
        </ActionIcon>
    )
}
const Notifications = () => {
    const {classes} = useStyles();
    const {data} = useCountUnreadQuery({});
    const dispatch = useAppDispatch();

    //invalidate feedback list
    useEffect(() => {
        dispatch(api.util.invalidateTags([{type: 'Feedback', id: 'LIST'}]))
    }, [api, dispatch, data])

    return (
        <Link to={'/cms/feedback'}>
            <Tooltip label={'Заявки с сайта'}>
                <Indicator label={data} disabled={!Boolean(data ? data > 0 : true)} size={16} color={'red'} mx={10}>
                    <ActionIcon
                        variant="default"
                        className={classes.actionIcon}
                        size={'lg'}
                    >
                        <IconBell strokeWidth={1.5} className={classes.icon}/>
                    </ActionIcon>
                </Indicator>
            </Tooltip>
        </Link>
    )
}
const Profile = () => {
    const {classes} = useStyles();
    return (
        <ActionIcon
            variant="default"
            className={classes.actionIcon}
            size={'lg'}
        >
            <IconUserCircle strokeWidth={1.5} className={classes.icon}/>
        </ActionIcon>
    )
}
