import {Badge, Button, Divider, Flex, Group, Modal, rem, Text, useMantineTheme} from "@mantine/core";
import {Dropzone, MS_EXCEL_MIME_TYPE,} from "@mantine/dropzone";
import {IconPhoto, IconTable, IconUpload, IconX} from "@tabler/icons-react";
import {useState} from "react";
import {useUploadDevicesMutation} from "../../api/device";
import {showNotification} from "@mantine/notifications";
import {IDevice} from "../../api/types";

type TProps = {
    isOpen: boolean,
    onClose: () => void
}
const toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
const UploadDevices = ({isOpen, onClose}: TProps) => {
    const theme = useMantineTheme();
    const [uploadedValues, setUploadedValues] = useState<IDevice[]>([])
    const [erroredValues, setErroredValues] = useState<IDevice[]>([])
    const [isLoading, setIsLoading] = useState(false);
    const [uploadFileApi] = useUploadDevicesMutation()
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    async function handleUpload() {
        if (uploadedFile) {
            //const blob = await toBase64(uploadedFile)
            const formData = new FormData();
            formData.append('file', uploadedFile)
            try {
                setIsLoading(true)
                const res = await uploadFileApi({file: formData}).unwrap()
                setUploadedValues(res.result.uploaded)
                setErroredValues(res.result.errored)
                setIsLoading(false)
                showNotification({color: 'blue', message: res.message})
            } catch (e) {
                setIsLoading(false)
                console.log('upload file error', e)
            }
        }
    }

    return (
        <Modal
            opened={isOpen} onClose={onClose}
            size={"xl"}
            centered={true}
            overlayProps={{
                color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
                opacity: 0.55,
                blur: 3,
            }}
            title={<>Загрузка устройств</>}
        >
            <Dropzone
                onDrop={(files) => setUploadedFile(files[0])}
                onReject={(files) => console.log('rejected files', files)}
                maxSize={3 * 1024 ** 2}
                accept={MS_EXCEL_MIME_TYPE}
            >
                <Group position="center" spacing="xl" style={{minHeight: rem(220), pointerEvents: 'none'}}>
                    <Dropzone.Accept>
                        <IconUpload
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX
                            size="3.2rem"
                            stroke={1.5}
                            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconTable size="3.2rem" stroke={1.5}/>
                    </Dropzone.Idle>

                    <div>
                        <Text size="xl" inline>
                            Перетащите или нажмите чтоб загрузить excel файл
                        </Text>
                    </div>
                </Group>

                {uploadedValues.length || erroredValues.length ? (
                    <>
                        <Text color={'green'}>Загружено: {uploadedValues.length}</Text>
                        <Text color={'red'}>Не Загружено: {erroredValues.length}</Text>
                        <Divider mt={10}/>
                        <Text>Всего: {uploadedValues.length + erroredValues.length}</Text>
                    </>
                ) : <></>}

            </Dropzone>
            <Text py={5} color={"dimmed"} underline>{uploadedFile?.name}</Text>
            <Flex mt={15} mb={10} justify={"flex-end"}>
                <Button disabled={!Boolean(uploadedFile)} onClick={handleUpload} loading={isLoading}>
                    Загрузить
                </Button>
            </Flex>
        </Modal>
    )
}

export default UploadDevices