import {forwardRef, useEffect, useState} from "react";
import {useDebouncedState} from "@mantine/hooks";
import {
    Autocomplete, Checkbox, createStyles,
    Divider, Group, MultiSelect, Paper,
    Select, Text, Textarea, TextInput
} from "@mantine/core";
import {useFormContext} from "./form-context";
import {useGetDevicesQuery} from "../../api/device";
import {useCreateDeviceTypeMutation, useGetDeviceTypesQuery} from "../../api/deviceType";
import {useCreateDeviceBrandMutation, useGetDeviceBrandsQuery} from "../../api/deviceBrand";
import {useCreateDeviceModelMutation, useGetDeviceModelsQuery} from "../../api/deviceModel";
import {useGetDeviceAppearancesQuery} from "../../api/deviceAppearance";
import {IDevice} from "../../api/types";

const useClientStyles = createStyles((theme) => ({
    dropdownItem: {
        padding: 5,
        cursor: "pointer",
        "&:hover": {
            background: theme.colors.gray[2]
        }
    },
    rowContainer: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        padding: '10px 0'
    },
    divider: {
        padding: "5px 0 10px 0"
    }
}))
const Device = () => {
    const form = useFormContext();
    const orderType = form.getTransformedValues().orderType;
    const {classes} = useClientStyles();
    const [value, setValue] = useDebouncedState('', 200);
    const [deviceNumberFilter, setDeviceNumberFilter] = useDebouncedState('', 200);
    const [openedDropdown, setOpenedDropdown] = useState<'type' | 'model' | 'brand' | null>(null)
    const [createdDeviceAppearances, setCreatedDeviceAppearances] = useState<{ _id: string, name: string }[]>([]);
    const {data = {content: []}, isFetching} = useGetDevicesQuery({
        page: 1, pageSize: 5, filter:
            JSON.stringify({
                "deviceNumber": {
                    "$regex": deviceNumberFilter,
                    "$options": "i"
                },
                "serial": {
                    "$regex":  value,
                    "$options": "i"
                }
            })
    });

    const {data: deviceTypes = {content: []}, isFetching: isFetchingDeviceTypes} = useGetDeviceTypesQuery({
        page: 1, pageSize: 999, sort: {column: "name", direction: 'ASC'}
    });

    const {data: deviceBrands = {content: []}, isFetching: isFetchingDeviceBrands} = useGetDeviceBrandsQuery({
        page: 1, pageSize: 999,
        filter: JSON.stringify({
            type: form.getInputProps('device.type').value
        }),
        sort: {column: "name", direction: 'descend'}
    }, {skip: !Boolean(form.getInputProps('device.type').value)});

    const {data: deviceModels = {content: []}, isFetching: isFetchingDeviceModels} = useGetDeviceModelsQuery({
        page: 1, pageSize: 999,
        filter: JSON.stringify({
            type: form.getInputProps('device.type').value,
            brand: form.getInputProps('device.brand').value
        })
    }, {skip: !Boolean(form.getInputProps('device.type').value && form.getInputProps('device.brand').value)});

    const {
        data: deviceAppearance = {content: []},
        isFetching: isFetchingDeviceAppearance
    } = useGetDeviceAppearancesQuery({
        page: 1, pageSize: 25
    });

    const [createDeviceType, createdDeviceType] = useCreateDeviceTypeMutation();
    const [createDeviceBrand, createdDeviceBrand] = useCreateDeviceBrandMutation();
    const [createDeviceModel, createdDeviceModel] = useCreateDeviceModelMutation();

    useEffect(() => {
        if (createdDeviceType.data) {
            form.setFieldValue('device.type', createdDeviceType.data.result._id)
        }
    }, [createdDeviceType.data])

    useEffect(() => {
        if (createdDeviceBrand.data) {
            form.setFieldValue('device.brand', createdDeviceBrand.data.result._id)
        }
    }, [createdDeviceBrand.data])

    useEffect(() => {
        if (createdDeviceModel.data) {
            form.setFieldValue('device.model', createdDeviceModel.data.result._id)
        }
    }, [createdDeviceModel.data])


    function onChangeSerial(v: string) {
        setValue(v)
        form.setFieldValue('device.serial', v)
    }

    function onChangeDeviceNumber(v: string) {
        setDeviceNumberFilter(v)
        form.setFieldValue('device.deviceNumber', v)
    }

    function onSelectDeviceNumber(v: { value: string, name: string, record: IDevice }) {
        form.setFieldValue('device', {
            deviceNumber: v.value,
            serial: v.record.serial,
            brand: v.record.brand._id,
            type: v.record.type._id,
            model: v.record.model._id,
            specs: v.record.specs
        })
    }

    function onSelectDevice(v: { value: string, name: string, record: IDevice }) {
        form.setFieldValue('device', {
            serial: v.value,
            brand: v.record.brand._id,
            type: v.record.type._id,
            model: v.record.model._id,
            deviceNumber: v.record.deviceNumber,
            specs: v.record.specs,
        })
    }

    return (
        <Paper p={"xs"}>
            <div><Text>Устройство</Text></div>
            <Divider className={classes.divider} color={'gray.1'}/>
            <Autocomplete
                withinPortal
                zIndex={301}
                withAsterisk={false}
                required={true}
                data={data.content.map(item => {
                    return {
                        name: `${item.type?.name} ${item.brand?.name} ${item.model?.name || ''}`,
                        value: item.deviceNumber || item.serial,
                        record: item
                    }
                }) || []}
                limit={5}
                w={350}
                mb={10}
                itemComponent={AutoCompleteItem}
                label={"Номер устройства"}
                {...form.getInputProps('device.deviceNumber')}
                onChange={onChangeDeviceNumber}
                onItemSubmit={onSelectDeviceNumber}
            />
            <Autocomplete
                withinPortal
                zIndex={301}
                withAsterisk={false}
                required={!form.getInputProps('withoutSerial').value}
                disabled={form.getInputProps('withoutSerial').value}
                data={data.content.map(item => {
                    return {
                        name: `${item.type?.name} ${item.brand?.name} ${item.model?.name || ""}`,
                        value: item.serial,
                        record: item
                    }
                }) || []}
                limit={5}
                itemComponent={AutoCompleteItem}
                label={<div style={{display: 'flex', alignItems: 'center'}}>Серийный номер (&nbsp;
                    <Checkbox
                        labelPosition={"left"}
                        size={"sm"}
                        label="не могу ввести"
                        {...form.getInputProps('withoutSerial')}
                    />&nbsp;)
                </div>}
                {...form.getInputProps('device.serial')}
                onChange={onChangeSerial}
                onItemSubmit={onSelectDevice}
            />

            <div className={classes.rowContainer}>
                <Select
                    label="Тип"
                    required={true}
                    disabled={form.getInputProps('withoutSerial').value ? false : !Boolean(form.getInputProps('device.serial').value)}
                    searchable
                    creatable
                    placeholder="Выбирите тип"
                    data={deviceTypes.content.map(item => {
                        return {label: item.name, value: item._id || ""}
                    })}
                    onDropdownOpen={() => setOpenedDropdown('type')}
                    onDropdownClose={() => setOpenedDropdown(null)}
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onCreate={query => {
                        createDeviceType({
                            name: query
                        })
                        return {value: "", name: query}
                    }}
                    {...form.getInputProps('device.type')}
                />
                <Select
                    label="Бренд"
                    required={true}
                    disabled={!Boolean(form.getInputProps('device.type').value)}
                    searchable
                    creatable
                    placeholder="Выбирите бренд"
                    data={deviceBrands.content.map(item => {
                        return {label: item.name, value: item._id || ""}
                    })}
                    onDropdownOpen={() => setOpenedDropdown('brand')}
                    onDropdownClose={() => setOpenedDropdown(null)}
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onCreate={query => {
                        createDeviceBrand({
                            type: form.getInputProps('device.type').value,
                            name: query
                        })
                        return {value: "", name: query}
                    }}
                    {...form.getInputProps('device.brand')}
                />
                <Select
                    label="Модель"
                    required={true}
                    disabled={!Boolean(form.getInputProps('device.type').value && form.getInputProps('device.brand').value)}
                    searchable
                    creatable
                    placeholder="Выбирите модель"
                    data={deviceModels.content.map(item => {
                        return {label: item.name, value: item._id || ""}
                    })}
                    onDropdownOpen={() => setOpenedDropdown('model')}
                    onDropdownClose={() => setOpenedDropdown(null)}
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onCreate={query => {
                        createDeviceModel({
                            type: form.getInputProps('device.type').value,
                            brand: form.getInputProps('device.brand').value,
                            name: query
                        })
                        return {value: "", name: query}
                    }}
                    {...form.getInputProps('device.model')}
                />
            </div>
            <div className={classes.rowContainer}>
                <Textarea
                    style={{width: '100%'}}
                    label="Тех. Характеристики"
                    {...form.getInputProps('device.specs')}
                    disabled={!Boolean(form.getInputProps('device.type').value && form.getInputProps('device.brand').value)}
                />
            </div>
            <div className={classes.rowContainer}>
                <TextInput
                    style={{width: '35%'}}
                    label="Пин"
                    required={orderType === 'client'}
                    disabled={orderType !== 'client'}
                    {...form.getInputProps('devicePassword')}
                />
                <MultiSelect
                    style={{width: '60%'}}
                    label="Внешний вид"
                    data={[...deviceAppearance.content, ...createdDeviceAppearances].map(item => {
                        return {label: item.name, value: item.name || ""}
                    })}
                    placeholder="Выбрать"
                    disabled={orderType !== 'client'}
                    searchable
                    creatable
                    getCreateLabel={(query) => `+ Создать ${query}`}
                    onCreate={(query) => {
                        setCreatedDeviceAppearances(prev => [...prev, {name: query, _id: query}])
                        return {value: query, label: query}
                    }}
                    {...form.getInputProps('deviceAppearance')}
                />
            </div>
        </Paper>
    )
}
export default Device;


const AutoCompleteItem = forwardRef<HTMLDivElement, { value: string, name: string, record: IDevice }>(
    ({value, name, ...others}: { value: string, name: string }, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text>{value}</Text>
                    <Text size="xs" color="dimmed">
                        {name}
                    </Text>
                </div>
            </Group>
        </div>
    )
);
