import React, {useCallback, useState} from "react";
import {ActionIcon, CopyButton, createStyles, Divider, Flex, Paper, Skeleton, Tabs, Text, Tooltip} from "@mantine/core";
import {useNavigate, useParams} from "react-router-dom";
import {
    IconCheck,
    IconCopy,
    IconHistory,
    IconCheckbox,
    IconX,
    IconUserSearch,
    IconArrowBack, IconEdit
} from "@tabler/icons-react";
import dayjs from "dayjs";
import {useReadOrderQuery} from "../../api/order";
import {statusObjects} from "../ordersTable/OrdersTable";
import {IOrder, IStatus} from "../../api/types";
import OrderByClient from "../OrderByClient";
import OrderStatusForm from "../orderStatusForm/OrderStatusForm";
import PrintOrder from "../printOrder/printOrder";
import {openUpdateOrder} from "../../app/app.slice";
import {useAppDispatch} from "../../app/hooks";
import OrderNotes from "./OrderNotes";


const useStyles = createStyles((theme) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    itemName: {
        color: theme.colorScheme === 'light' ? theme.colors.dark[9] : theme.colors.gray[5]
    }
}))
const initialStatusForm = {opened: false}
const countPayment = (prepayment: number, price: number): string => {
    let pay = 'Цена не указана';
    if (price) {
        pay = `${price - (prepayment || 0)} ₽`
    }
    return pay
};
const OrderRead = () => {
    const {id} = useParams();
    const [ordersByClient, setOrdersByClient] = useState<{ opened: boolean, record?: IOrder }>(initialStatusForm);
    const [statusForm, setStatusForm] = useState<{ opened: boolean, record?: IOrder }>(initialStatusForm);
    const {data, isFetching} = useReadOrderQuery({id: id || ""}, {skip: !id});

    const openOrdersByClient = useCallback(() => {
        setOrdersByClient({opened: true, record: data})
    }, [data]);
    const openStatusForm = useCallback(() => {
        setStatusForm({opened: true, record: data})
    }, [data]);

    if (!data) {
        return (
            <div style={{padding: 10}}>
                <Skeleton height={30}/>
                <Skeleton height={30} mt={15}/>
                <Skeleton height={30} mt={15}/>
                <Skeleton height={30} mt={15}/>
            </div>
        )
    }
    return (
        <>
            <OrderStatusForm record={statusForm.record} opened={statusForm.opened}
                             close={() => setStatusForm(initialStatusForm)}/>
            <OrderByClient order={ordersByClient.record} opened={ordersByClient.opened}
                           onClose={() => setOrdersByClient(initialStatusForm)}/>
            <Header
                data={data}
                openStatusForm={openStatusForm}
            />
            <Tabs defaultValue={"order"} pr={20} mt={10}>
                <Tabs.List>
                    <Tabs.Tab value="order">Заказ</Tabs.Tab>
                    <Tabs.Tab value="notes">Заметки</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="order" py={10}>
                    <Flex direction={{base: 'column', sm: 'row'}} gap={{base: 'sm', sm: 'lg'}}>
                        {data.orderType !== 'guarantee' ? (
                            <Client data={data} openOrdersByClient={openOrdersByClient}/>) : <></>}
                        <Device data={data}/>
                    </Flex>
                    <Flex direction={{base: 'column', sm: 'row'}}>
                        <OtherInfo data={data}/>
                    </Flex>
                </Tabs.Panel>

                <Tabs.Panel value="notes" py={10}>
                    <OrderNotes/>
                </Tabs.Panel>
            </Tabs>

        </>
    )
}
export default OrderRead

const Header = (props: { data: IOrder, openStatusForm: () => void, }) => {
    const {data, openStatusForm} = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {classes} = useStyles();
    return (
        <Paper p={10} mr={20}>
            <div className={classes.header}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <ActionIcon variant="outline" onClick={() => navigate(-1)} color={'blue'} mr={10}>
                        <IconArrowBack size="1.2rem"/>
                    </ActionIcon>
                    <Text size={20}>Заказ № {data.orderNo}&nbsp;
                        <Text span color={statusObjects[data.status as IStatus].color}>
                            ({statusObjects[data.status as IStatus].text})
                        </Text>
                    </Text>
                </div>
                <div style={{display: 'flex'}}>
                    <Tooltip label={'Сменить статус заказа'}>
                        <ActionIcon variant="outline" onClick={openStatusForm} color={'blue'}>
                            <IconHistory size="1.2rem"/>
                        </ActionIcon>
                    </Tooltip>
                    <PrintOrder orderId={props.data._id} status={props.data.status} size={'md'}/>
                    <Tooltip label={'Редактировать заказ'}>
                        <ActionIcon variant="outline" color={'red'} ml={10}
                                        onClick={() => dispatch(openUpdateOrder({id: data._id}))}
                        >
                            <IconEdit size="1.2rem"/>
                        </ActionIcon>
                    </Tooltip>
                </div>
            </div>
        </Paper>
    )
}
const Client = (props: { data: IOrder, openOrdersByClient: () => void }) => {
    const {data, openOrdersByClient} = props;
    const {classes} = useStyles();
    return (
        <Paper p={10} mr={20} mt={5} w={'100%'}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Text weight={700} color={'cyan.8'}>Профайл клиента</Text>
                <Tooltip label={'Заказы клиента'}>
                    <ActionIcon color={'cyan.6'} ml={10} variant="outline" onClick={openOrdersByClient}>
                        <IconUserSearch size="1.2rem"/>
                    </ActionIcon>
                </Tooltip>
            </div>
            <Divider mt={5} mb={15}/>
            <div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{width: '50%'}}>
                        <Text><Text span className={classes.itemName} weight={500}>Имя
                            Клиента:</Text> {data.client.name}
                        </Text>
                    </div>
                    <div style={{width: '50%'}}>
                        <Text><Text span className={classes.itemName} weight={500}>Номер телефона:</Text> <a
                            href={`tel:${data.client.phone}`}>{data.client.phone}</a>
                        </Text>
                    </div>
                </div>
                <Text mt={10}>
                    <Text span className={classes.itemName} weight={500}>Откуда
                        узнал:</Text> {data.whereKnown?.name}
                </Text>
            </div>
        </Paper>
    )
}
const Device = (props: { data: IOrder, }) => {
    const {data} = props;
    const {classes} = useStyles();
    return (
        <Paper p={10} mr={20} mt={5} w={'100%'}>
            <Text weight={700} color={'cyan.8'}>Устройство на приёме</Text>
            <Divider mt={5} mb={15}/>
            <div>
                <div style={{width: '100%'}}>
                    <Text><Text span className={classes.itemName}
                                weight={500}>Устройство:</Text> {data.device.type?.name} {data.device.brand?.name}, {data.device.model?.name}
                    </Text>
                </div>
                <div style={{width: '100%', display: 'flex', marginTop: 10}}>
                    <Text className={classes.itemName} span weight={500}>Серийный номер:</Text>
                    <div style={{display: 'flex'}}>
                        <Text> &nbsp;{data.device.serial}</Text>
                        <CopyButton value={data.device.serial}>
                            {({copied, copy}) => (
                                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                    <ActionIcon color={copied ? 'teal' : 'blue'} onClick={copy}
                                                style={{color: 'cyan.6'}}>
                                        {copied ? <IconCheck size="1rem"/> : <IconCopy size="1rem"/>}
                                    </ActionIcon>
                                </Tooltip>
                            )}
                        </CopyButton>
                    </div>
                </div>
                {data.device.deviceNumber && (
                    <div style={{width: '100%', display: 'flex', marginTop: 10}}>
                        <Text className={classes.itemName} span weight={500}>Артикул:</Text>
                        <div style={{display: 'flex'}}>
                            <Text> &nbsp;{data.device.deviceNumber}</Text>
                            <CopyButton value={data.device.deviceNumber || ""}>
                                {({copied, copy}) => (
                                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                        <ActionIcon color={copied ? 'teal' : 'blue'} onClick={copy}
                                                    style={{color: 'cyan.6'}}>
                                            {copied ? <IconCheck size="1rem"/> : <IconCopy size="1rem"/>}
                                        </ActionIcon>
                                    </Tooltip>
                                )}
                            </CopyButton>
                        </div>
                    </div>
                )}
                {data.device.specs && (
                    <Text mt={10}><Text className={classes.itemName} span
                                        weight={500}>Тех. характеристика:</Text> {data.device.specs}
                    </Text>
                )}

                <Text mt={10}><Text className={classes.itemName} span
                                    weight={500}>Пин:</Text> {data.devicePassword}
                </Text>
                <Text mt={10}><Text className={classes.itemName} span weight={500}>Внешний
                    вид:</Text> {data.appearance}
                </Text>
                <Text mt={10}><Text className={classes.itemName} span weight={500}>Описание
                    проблемы:</Text> {data.problem}</Text>
            </div>
        </Paper>
    )
}
const OtherInfo = (props: { data: IOrder, }) => {
    const {data} = props;
    const {classes} = useStyles();
    const toPayment = countPayment(data.prepayment, data.price)
    return (
        <Paper p={10} mr={20} mt={20} w={'100%'}>
            <Text weight={700} color={'cyan.8'}>Информация</Text>
            <Divider mt={5} mb={15}/>
            <div>
                <div>
                    <div style={{width: '100%'}}>
                        <Text><Text span className={classes.itemName} weight={500}>Принял
                            заказ:</Text> {data.manager.name}</Text>
                        <Text mt={10}><Text span className={classes.itemName}
                                            weight={500}>Дата:</Text> {dayjs(data.createdAt).format('YYYY-MM-DD HH:mm')}
                        </Text>
                        <Text mt={10}><Text className={classes.itemName} span
                                            weight={500}>Мастер:</Text> {data.executor?.name}
                        </Text>
                        <Text mt={10} color={'orange.6'}><Text className={classes.itemName} span
                                                               weight={500}>Предоплата:</Text> {data.prepayment || 0} ₽
                        </Text>
                        <Text mt={10} color={'orange.6'}><Text className={classes.itemName} span weight={500}>Цена
                            ремонта:</Text> {data.price || 0} ₽
                        </Text>
                    </div>
                    <Divider mt={10} mb={10}/>
                    <Text mt={10} color={'green.6'}><Text className={classes.itemName} span weight={500}>К
                        оплате:</Text> {toPayment}
                    </Text>
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                        <Text className={classes.itemName} span weight={500}>Статус оплаты:</Text>
                        {data.status === 'payed' ? (
                            <Text color={'green.6'}
                                  style={{display: 'flex', alignItems: 'center'}}> &nbsp;Оплачен <IconCheckbox
                                color={'green'}/></Text>
                        ) : <Text color={'red.6'} style={{display: 'flex', alignItems: 'center'}}> &nbsp;Не
                            оплачен <IconX color={'red'}/></Text>}
                    </div>
                    {data.status === 'payed' && (
                        <Text mt={10} color={'green.6'}><Text className={classes.itemName} span weight={500}>Тип
                            оплаты:</Text>
                            &nbsp;{data.paymentType === 'cash' ? 'Нал' : 'Безнал'}
                        </Text>
                    )}
                </div>

            </div>
        </Paper>
    )
}
