import {api} from "./api";
import {ICrudResponse, IDevice, IDeviceCreate, IQueryParams} from "./types";

export const deviceApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDevices: build.query<{ content: IDevice[], totalPages: number }, IQueryParams>({
            query: (params) => ({
                url: `/api/device/query`,
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    filter: params.filter,
                    search: params.search,
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Device', tag}],
        }),
        createDevice: build.mutation<ICrudResponse, IDeviceCreate>({
            query: (payload) => ({
                url: '/api/device/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Device'}]
        }),
        updateDevice: build.mutation<ICrudResponse, IDeviceCreate & { _id: string }>({
            query: (payload) => ({
                url: '/api/device/create',
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{type: 'Device'}]
        }),
        readDevice: build.query<IDevice, { _id?: string }>({
            query: (params) => ({
                url: `/api/device/read`,
                method: "POST",
                body: {
                    id: params._id
                }
            }),
            providesTags: (result, error, tag) => [{type: 'Device', tag}],
        }),
        removeDevice: build.mutation<ICrudResponse, { _id: string }>({
            query: (payload) => ({
                url: '/api/device/delete',
                method: "POST",
                formData: true,
                body: payload
            }),
            invalidatesTags: [{type: 'Device'}]
        }),
        uploadDevices: build.mutation<ICrudResponse & { result: { uploaded: IDevice[], errored: IDevice[] } }, { file: any }>({
            query: (payload) => ({
                url: '/api/device/uploadDevices',
                method: "POST",
                formData: true,
                body: payload.file
            }),
            invalidatesTags: [{type: 'Device'}]
        }),
    })
})

export const {
    useCreateDeviceMutation,
    useUpdateDeviceMutation,
    useRemoveDeviceMutation,
    useReadDeviceQuery,
    useGetDevicesQuery,
    useUploadDevicesMutation,
} = deviceApi