import {
    Button,
    createStyles,
    Divider,
    LoadingOverlay,
    Pagination,
    Paper,
    ScrollArea,
    Table,
    TextInput
} from "@mantine/core";
import {IconSearch} from "@tabler/icons-react";
import Th from "./ordersTable/Th";
import React from "react";
import {usePagination} from "@mantine/hooks";
import {findByString} from "../utils/lib";

const useStyles = createStyles((theme) => ({
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 5px',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#fff'
    },
    tableHead: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    },
    table: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    },
    clientNameText: {
        '&:hover': {
            textDecoration: "underline"
        }
    }
}))

interface IColumn {
    name: string,
    label: string,
    type?: string,
    width?: string,
    render?: (string: any, {}: any) => any,
    sortable?: boolean,
}

interface IProps {
    isFetching: boolean,
    columns: IColumn[],
    data: { content: any[], totalPages: number },
    onRowClick?: (item: any) => void,
    onChangePage: (page: number) => void,
    page: number,
    sort?: { column: string, direction: string },
    setSort?: (v: string) => void,
    onSearch?: (v: string) => void,
    enableSearch?: boolean,
    searchPlaceholder?: string,
    creatable?: boolean,
    onClickCreate?: () => void
}


const DefaultTable = (props: IProps) => {
    const {
        isFetching, columns, data, onRowClick = () => {
        }, page, onChangePage, onSearch, sort, setSort, enableSearch, searchPlaceholder, creatable, onClickCreate
    } = props;
    const {classes} = useStyles();
    const pagination = usePagination({total: data.totalPages, initialPage: 1, page: page, onChange: onChangePage})
    return (
        <>
            <Paper sx={{display: 'flex', alignItems: 'center'}} mb="md">
                {(enableSearch && onSearch) && (
                    <TextInput
                        style={{width: '100%'}}
                        placeholder={searchPlaceholder}
                        icon={<IconSearch size="0.9rem" stroke={1.5}/>}
                        onChange={(e) => onSearch(e.target.value)}
                    />
                )}
                {(creatable && onClickCreate) && (
                    <Button ml={10} onClick={onClickCreate}>Создать</Button>
                )}
            </Paper>
            <ScrollArea>
                <LoadingOverlay visible={isFetching} overlayBlur={1}/>
                <Table highlightOnHover className={classes.table} withColumnBorders>
                    <thead className={classes.tableHead}>
                    <tr>
                        {columns.map(column => (
                            <Th
                                key={column.name}
                                sortable={Boolean(column.sortable)}
                                width={column.width || 'auto'}
                                onSort={() => setSort && setSort(column.name)}
                                sorted={Boolean(sort?.column === column.name && sort.direction)}
                                reversed={Boolean(sort?.column === column.name && sort.direction === 'descend')}
                            >
                                {column.label}
                            </Th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {(data || {content: []}).content.map((item: any, i: number) => {
                        return (
                            <tr key={i} onClick={() => onRowClick(item)}>
                                {columns.map((column: IColumn) => {
                                    return (
                                        <td key={column.name}>
                                            {
                                                column.render ?
                                                    column.render(findByString(item, column.name), item) :
                                                    findByString(item, column.name)
                                            }
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <Divider/>
                <div className={classes.paginationContainer}>
                    <Pagination
                        total={(data || {totalPages: 0}).totalPages}
                        onChange={(value) => {
                            pagination.setPage(value)
                        }}
                    />
                </div>
            </ScrollArea>
        </>
    )
}
export default DefaultTable;

DefaultTable.defaultProps = {
    setSort: () => {
    },
    sort: {column: null},
    searchPlaceholder: 'Поиск...'

}