import {createStyles, Drawer, Button, Flex} from "@mantine/core";
import {notifications, showNotification} from "@mantine/notifications";
import {closeOrderForm} from "../../app/app.slice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {OrderFormProvider, useOrderForm} from "./form-context";
import {IOrderCreate} from "../../api/types";
import {
    useCreateOrderMutation, useDeleteOrderMutation,
    useLazyReadOrderQuery,
    useUpdateOrderMutation
} from "../../api/order";
import {isErrorWithMessage} from "../../api/api";
import Client from "./Client";
import Device from "./Device";
import Other from "./Other";
import React, {useEffect, useState} from "react";
import {IconMessage, IconTrash} from "@tabler/icons-react";
import AddOrderNote from "./addOrderNote";
import {useConfirmationModal} from "../../hooks/useConfirmationModal";
import {useNavigate} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    header: {
        zIndex: 999,
        padding: '0.5rem',
        background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#fff'
    },
    footer: {
        bottom: 0,
        background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#fff',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0.5rem 1.5rem'
    }
}))
const OrderForm = () => {
    const {selectedOffice, user} = useAppSelector(state => state.authorization)
    const [isOpenOrderNote, setIsOpenOrderNote] = useState<boolean>(false)
    const [ConfirmModal, confirmDelete] = useConfirmationModal({})
    const [createOrder] = useCreateOrderMutation();
    const [updateOrder] = useUpdateOrderMutation();
    const [deleteOrder] = useDeleteOrderMutation();
    const [readOrder] = useLazyReadOrderQuery();
    const navigate = useNavigate();
    const role = useAppSelector(state => state.authorization.user.role)
    console.log(role)
    const form = useOrderForm({
        initialValues: {
            orderType: 'guarantee',
            office: selectedOffice || "",
            client: {
                name: "",
                phone: ""
            },
            whereKnown: "",
            executor: "",
            device: {
                brand: "",
                model: "",
                type: "",
                serial: ""
            },
            manager: user.userId || "",
            isOtherExpenses: false,
            otherExpensesComment: "",
            paymentType: "",
            prepayment: undefined,
            problem: "",
            readyDate: "",
            urgently: false,
            price: undefined,
            createdAt: "",
            deviceAppearance: [],
            devicePassword: "",
            equipment: "",
            expenses: 0,
            guarantee: "",
            status: "NEW",
            withoutSerial: false,
            note: '',
        }
    })
    const dispatch = useAppDispatch();
    const {createOrderStatus, predefinedOrderData} = useAppSelector(state => state.app);
    const {classes} = useStyles();

    useEffect(() => {
        if (createOrderStatus.opened && createOrderStatus.recordId) {
            readRecord(createOrderStatus.recordId)
            return
        }
        if (createOrderStatus.opened && predefinedOrderData?.client) {
            form.setFieldValue('client', predefinedOrderData.client)
        }
    }, [predefinedOrderData, createOrderStatus.opened])

    useEffect(() => {
        form.setFieldValue('office', selectedOffice)
    }, [selectedOffice])

    async function readRecord(id: string) {
        try {
            const res = await readOrder({id}).unwrap();
            form.setValues({
                _id: res._id,
                client: res.client || {},
                devicePassword: res.devicePassword,
                paymentType: res.paymentType,
                commission: res.commission,
                price: res.price,
                equipment: res.equipment,
                deviceAppearance: res.appearance.split(", "),
                prepayment: res.prepayment,
                problem: res.problem,
                withoutSerial: res.withoutSerial,
                urgently: res.urgently,
                device: {
                    brand: res.device.brand._id,
                    type: res.device.type._id,
                    model: res.device.model._id,
                    serial: res.device.serial,
                    specs: res.device.specs,
                    deviceNumber: res.device.deviceNumber,
                },
                executor: res.executor?._id,
                whereKnown: res.whereKnown?._id,
                manager: res.manager?._id,
                status: res.status,
            })
        } catch (e) {
            console.log(e)
        }
    }

    async function onSubmit(values: IOrderCreate) {
        try {
            createOrderStatus.recordId ? await updateOrder(values).unwrap() : await createOrder(values).unwrap();
            showNotification({color: 'green', message: createOrderStatus.recordId ? "Заказ обновлен" : "Заказ создан"})
            dispatch(closeOrderForm())
            form.reset();
        } catch (err) {
            if (isErrorWithMessage(err)) {
                notifications.show({color: 'red', message: err.data.message})
            }
        }
    }

    function onCloseOrderNote() {
        setIsOpenOrderNote(false)
    }

    function saveOrderNote(value: string) {
        form.setFieldValue('note', value)
    }

    async function onClickDelete() {

        try {
            const isConfirm = await confirmDelete();

            if(!isConfirm) return;

            createOrderStatus.recordId &&
            await deleteOrder({id: createOrderStatus.recordId}).unwrap();
            dispatch(closeOrderForm())
            navigate('/')
            showNotification({color: 'green', message: 'Заказ удален'})
        } catch (err) {
            if (isErrorWithMessage(err)) {
                notifications.show({color: 'red', message: err.data.message})
            }
        }
    }

    return (
        <div>
            <Drawer.Root opened={createOrderStatus.opened} onClose={() => dispatch(closeOrderForm())}
                         size={"xl"}
                         position={"right"}
                         zIndex={300}
            >
                <Drawer.Overlay/>
                <Drawer.Content>
                    <form onSubmit={form.onSubmit(async (values) => {
                        await onSubmit(values)
                    })}>
                        <Drawer.Header className={classes.header}>
                            <Drawer.Title/>
                            <Drawer.CloseButton/>
                        </Drawer.Header>
                        <Drawer.Body>
                            <OrderFormProvider form={form}>
                                <Client/>
                                <Device/>
                                <Other/>
                            </OrderFormProvider>
                        </Drawer.Body>
                        <Drawer.Header className={classes.footer}>
                            <Flex align={'center'} gap={15}>
                                <Button variant={'outline'} leftIcon={<IconMessage/>} color={'violet'}
                                        onClick={() => setIsOpenOrderNote(true)}
                                >
                                    Добавить заметку
                                </Button>
                                {(createOrderStatus.recordId && role === 'admin') && (
                                    <Button variant={'outline'} leftIcon={<IconTrash/>}
                                            color={'red'}
                                            onClick={onClickDelete}
                                    >
                                        Удалить
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    color={'cyan'}
                                >
                                    {createOrderStatus.recordId ? 'Обновить' : 'Создать Заказ'}
                                </Button>
                            </Flex>
                        </Drawer.Header>
                    </form>
                </Drawer.Content>
            </Drawer.Root>
            <AddOrderNote
                isOpen={isOpenOrderNote}
                onClose={onCloseOrderNote}
                onReturnValue={saveOrderNote}
                currentNote={form.getInputProps('note').value}
            />
            <ConfirmModal/>
        </div>
    )
}
export default OrderForm;
