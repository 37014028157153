export function findByString(values: any, path: string) {
    if (values !== null && path) {
        try {
            path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            path = path.replace(/^\./, '');           // strip a leading dot
            let a = path.split('.');
            if (values[path]) {
                return values[path]
            }
            for (let i = 0, n = a.length; i < n; ++i) {
                let k = a[i];
                if (k in values) {
                    values = values[k];
                } else {
                    return;
                }
            }
            return values;
        } catch (e) {
        }
    } else {
        if (path === '') {
            return values
        }
        return undefined;
    }
}