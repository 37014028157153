import {Button, Flex, Modal, Textarea} from "@mantine/core";
import {useEffect, useState} from "react";

type TProps = {
    isOpen: boolean;
    onClose: () => void;
    onReturnValue: (value: string) => void,
    currentNote?: string
}
const AddOrderNote = ({isOpen, onClose, onReturnValue, currentNote}: TProps) => {
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        if (isOpen && currentNote) {
            setValue(currentNote)
        }
        if(!isOpen) {
            setValue('')
        }
    }, [isOpen, currentNote]);

    return (
        <Modal opened={isOpen} onClose={onClose} title={'Заметка к заказу'} zIndex={400}>
            <Textarea
                value={value}
                required={true}
                onChange={e => setValue(e.target.value)}
            />

            <Flex mt={10} justify={'flex-end'}>
                <Button onClick={() => {
                    onReturnValue(value)
                    onClose();
                }}>
                    Добавить
                </Button>
            </Flex>
        </Modal>
    )
}

export default AddOrderNote;